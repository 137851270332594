import { Body_upload_portfolio } from '../../generated/facts'
import { formatDateForBackend } from '../datetime'
import { PortfolioType } from './parsePortfolio'

export type CreatePortfolio = {
  name: string
  type: PortfolioType
  currency: string
  totalValue: number
  valuationDate: Date
  file: File
}

export const formatCreatePortfolioRequest = (
  portfolio: CreatePortfolio,
): Body_upload_portfolio => ({
  portfolio_name: portfolio.name,
  total_value: portfolio.totalValue,
  valuation_date: formatDateForBackend(portfolio.valuationDate),
  portfolio_file: portfolio.file,
  benchmark: portfolio.type === 'benchmark',
  identifier_col: 'ISIN',
  weight_col: 'Weight',
  name_col: 'Name',
})
